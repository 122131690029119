import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/Seo"

import HeroButtons from "../components/templates/common/HeroButtons"
import ContentImageIcon from "../components/templates/whyChoose/ContentImageIcon"
import BoldTitle from "../components/templates/common/BoldTitle"
import ThreeImages from "../components/templates/whyChoose/ThreeImages"
import DrMo from "../components/templates/common/DrMo"
import WysiwygHero from "../components/templates/whyChoose/WysiwygHero"
import GivingBackIcons from "../components/templates/whyChoose/GivingBackIcons"
import BlockImageLinks from "../components/templates/whyChoose/BlockImageLinks"
import ReadyHero from "../components/templates/common/ReadyHero"

const WhyChoose = props => {
  const {
    hero,
    contentImageIcon,
    boldTitle,
    threeImages,
    drMo,
    wysiwygHero,
    givingBackIcons,
    blockImageLinks,
    readyHero,
    seoInfo,
  } = props.data

  const heroData = hero.template.templatePageWhyChoose
  const contentImageIconData = contentImageIcon.template.templatePageWhyChoose
  const boldTitleData = boldTitle.template.templatePageWhyChoose
  const threeImagesData = threeImages.template.templatePageWhyChoose
  const drMoData = drMo.template.templatePageWhyChoose
  const wysiwygHeroData = wysiwygHero.template.templatePageWhyChoose
  const givingBackIconsData = givingBackIcons.template.templatePageWhyChoose
  const blockImageLinksData = blockImageLinks.template.templatePageWhyChoose
  const readyHeroData = readyHero.template.templatePageWhyChoose

  return (
    <Layout>
      <Seo
        title={seoInfo.seoFields.swbThemeMetaTitle}
        description={seoInfo.seoFields.swbThemeDescription}
        //metaImg={seoInfo.seoFields.swbThemeImage.localFile.relativePath}
        location={props.location.pathname}
      />
      <HeroButtons data={heroData} />
      <ContentImageIcon data={contentImageIconData} />
      <BoldTitle data={boldTitleData} />
      <ThreeImages data={threeImagesData} />
      <DrMo data={drMoData} />
      <WysiwygHero data={wysiwygHeroData} />
      <GivingBackIcons data={givingBackIconsData} />
      <BlockImageLinks data={blockImageLinksData} />
      <ReadyHero data={readyHeroData} />
    </Layout>
  )
}

export const whyChooseTempQuery = graphql`
  query whyChooseTempPage($id: String!) {
    seoInfo: wpPage(id: { eq: $id }) {
      seoFields {
        swbThemeDescription
        swbThemeMetaTitle
        swbThemeImage {
          localFile {
            relativePath
          }
        }
      }
    }

    hero: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_WhyChoose {
          templatePageWhyChoose {
            buttonOneType
            buttonOneText
            buttonOneSlug
            buttonOneUrl
            buttonOnePhone
            buttonTwoType
            buttonTwoText
            buttonTwoSlug
            buttonTwoUrl
            buttonTwoPhone
            heroImage {
              altText
              sourceUrl
              localFile {
                url
                childImageSharp {
                  gatsbyImageData(width: 2500)
                }
              }
            }

            heroLogo {
              altText
              sourceUrl
              localFile {
                url
                childImageSharp {
                  gatsbyImageData(width: 1500)
                }
              }
            }
          }
        }
      }
    }

    contentImageIcon: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_WhyChoose {
          templatePageWhyChoose {
            ciiContent
            ciiImage {
              altText
              sourceUrl
              localFile {
                url
                childImageSharp {
                  gatsbyImageData(width: 2500)
                }
              }
            }

            ciiIcon {
              altText
              sourceUrl
              localFile {
                url
                childImageSharp {
                  gatsbyImageData(width: 1500)
                }
              }
            }
          }
        }
      }
    }

    boldTitle: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_WhyChoose {
          templatePageWhyChoose {
            boldTitle
            boldTitleContent
          }
        }
      }
    }

    threeImages: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_WhyChoose {
          templatePageWhyChoose {
            threeContent
            threeButtonText
            threeButtonSlug
            threeImageOne {
              altText
              sourceUrl
              localFile {
                url
                childImageSharp {
                  gatsbyImageData(width: 1500)
                }
              }
            }

            threeImageTwo {
              altText
              sourceUrl
              localFile {
                url
                childImageSharp {
                  gatsbyImageData(width: 1500)
                }
              }
            }

            threeImageThree {
              altText
              sourceUrl
              localFile {
                url
                childImageSharp {
                  gatsbyImageData(width: 1500)
                }
              }
            }
          }
        }
      }
    }

    drMo: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_WhyChoose {
          templatePageWhyChoose {
            drMoContent
            drMoTitle
            drMoReverse
            drMoIcon {
              altText
              sourceUrl
              localFile {
                url
                childImageSharp {
                  gatsbyImageData(width: 1500)
                }
              }
            }
          }
        }
      }
    }

    wysiwygHero: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_WhyChoose {
          templatePageWhyChoose {
            wysiwygHeroContent
            wysiwygHero {
              altText
              sourceUrl
              localFile {
                url
                childImageSharp {
                  gatsbyImageData(width: 2500)
                }
              }
            }
          }
        }
      }
    }

    givingBackIcons: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_WhyChoose {
          templatePageWhyChoose {
            gbiButtonRequired
            gbiButtonText
            gbiButtonSlug
            gbiIcons {
              url
              logo {
                altText
                sourceUrl
                localFile {
                  url
                  childImageSharp {
                    gatsbyImageData(width: 2500)
                  }
                }
              }
            }
          }
        }
      }
    }

    blockImageLinks: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_WhyChoose {
          templatePageWhyChoose {
            bilBlocks {
              title
              content
              buttonText
              buttonSlug
              image {
                altText
                sourceUrl
                localFile {
                  url
                  childImageSharp {
                    gatsbyImageData(width: 2500)
                  }
                }
              }
            }
          }
        }
      }
    }

    readyHero: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_WhyChoose {
          templatePageWhyChoose {
            readyHeroTitle
            readyHeroContent
            readyHeroButtonText
            readyHeroButtonSlug
            readyHeroImage {
              altText
              sourceUrl
              localFile {
                url
                childImageSharp {
                  gatsbyImageData(width: 2500)
                }
              }
            }
          }
        }
      }
    }
  }
`

export default WhyChoose
