import { Link } from "gatsby"
import styled from "styled-components"
import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Btn1Blue, H2Blue } from "../../../styles/helpers"

const ThreeImages = ({ data }) => {
  const imageOneDisplay = getImage(
    data.threeImageOne.localFile.childImageSharp.gatsbyImageData
  )
  const imageOneAlt = data.threeImageOne.altText

  const imageTwoDisplay = getImage(
    data.threeImageTwo.localFile.childImageSharp.gatsbyImageData
  )
  const imageTwoAlt = data.threeImageTwo.altText

  const imageThreeDisplay = getImage(
    data.threeImageThree.localFile.childImageSharp.gatsbyImageData
  )
  const imageThreeAlt = data.threeImageThree.altText
  return (
    <SectionStyled>
      <div className="wrapper">
        <div className="images">
          <div className="img-wrap img-one">
            <GatsbyImage
              image={imageOneDisplay}
              alt={imageOneAlt}
              layout="fullWidth"
              formats={["auto", "webp", "avif"]}
            />
          </div>
          <div className="image-right">
            <div className="img-wrap img-two">
              <GatsbyImage
                image={imageTwoDisplay}
                alt={imageTwoAlt}
                layout="fullWidth"
                formats={["auto", "webp", "avif"]}
              />
            </div>
            <div className="img-wrap img-three">
              <GatsbyImage
                image={imageThreeDisplay}
                alt={imageThreeAlt}
                layout="fullWidth"
                formats={["auto", "webp", "avif"]}
              />
            </div>
          </div>
        </div>
        <div className="content">
          <h3>{data.threeContent}</h3>
          <div className="btn">
            <Link to={`/${data.threeButtonSlug}`}>{data.threeButtonText}</Link>
          </div>
        </div>
      </div>
    </SectionStyled>
  )
}

const SectionStyled = styled.section`
  margin-bottom: 5rem;

  @media (min-width: 768px) {
    margin-bottom: 10rem;
  }

  @media (min-width: 1025px) {
    margin-bottom: 15rem;
  }

  .wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  .images {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;

    @media (min-width: 768px) {
      width: calc(65%);
    }

    @media (min-width: 1025px) {
      width: calc(65%);
    }

    .img-one {
      width: calc(66.66%);

      @media (min-width: 768px) {
        width: calc(66.66%);
      }
    }

    .image-right {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: space-between;
      width: calc(33.33% - 1.5rem);
      margin-left: 1.5rem;

      @media (min-width: 768px) {
        width: calc(33.33% - 1.5rem);
        margin-left: 1.5rem;
      }
    }
  }

  .content {
    width: calc(100%);
    margin-top: 4rem;
    padding: 2.5rem 2rem;

    @media (min-width: 768px) {
      width: calc(35%);
      margin-top: 0;
      padding: 2.5rem 2rem 2.5rem 5rem;
    }

    @media (min-width: 1025px) {
      width: calc(35%);
      padding: 2.5rem 2rem 2.5rem 5rem;
      max-width: 35rem;
    }

    h3 {
      ${H2Blue};
    }

    a {
      ${Btn1Blue};
    }
  }
`

export default ThreeImages
