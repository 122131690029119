import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Btn1Blue, standardWrapper } from "../../../styles/helpers"
import BackgroundOne from "../../Icons/BackgroundOne"

const GivingBackIcons = ({ data }) => {
  return (
    <DivStyled>
      <div className="wrapper">
        {data.gbiButtonRequired && (
          <div className="button">
            <Link to={`/${data.gbiButtonSlug}`}>{data.gbiButtonText}</Link>
          </div>
        )}
        {data.gbiIcons.map((icon, index) => {
          const imageDisplay = getImage(
            icon.logo.localFile.childImageSharp.gatsbyImageData
          )
          const imageAlt = icon.logo.altText
          return (
            <LogoLink href={icon.url} key={index}>
              <GatsbyImage
                image={imageDisplay}
                alt={imageAlt}
                layout="fullWidth"
                formats={["auto", "webp", "avif"]}
              />
            </LogoLink>
          )
        })}
      </div>
      <div className="bg-one">
        <BackgroundOne />
      </div>
    </DivStyled>
  )
}

const DivStyled = styled.div`
  position: relative;

  .wrapper {
    ${standardWrapper};
    align-items: center;
  }

  .button {
    width: 100%;
    text-align: center;

    a {
      ${Btn1Blue};
    }
  }

  .bg-one {
    position: absolute;
    bottom: -25rem;
    right: -30rem;
    max-width: 40rem;
    margin: auto;
    z-index: -1;

    @media (min-width: 768px) {
      bottom: -25rem;
      right: -30rem;
    }

    @media (min-width: 1025px) {
      bottom: -25rem;
      right: -25rem;
    }

    @media (min-width: 1200px) {
      bottom: -25rem;
      right: -20rem;
    }
  }
`

const LogoLink = styled.a`
  width: calc(100% / 2);
  padding: 2rem;

  @media (min-width: 768px) {
    width: calc(100% / 4);
    padding: 2rem;
  }

  @media (min-width: 1025px) {
    width: calc(100% / 4);
    padding: 3rem;
  }
`

export default GivingBackIcons
