import React from "react"
import styled from "styled-components"
import {
  B2LightBlue,
  colors,
  H1White,
  standardWrapper,
} from "../../../styles/helpers"

const BoldTitle = ({ data }) => {
  return (
    <SectionStyled>
      <div className="wrapper">
        <div className="title">
          <h2>{data.boldTitle}</h2>
        </div>
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: data.boldTitleContent }}
        />
      </div>
    </SectionStyled>
  )
}

const SectionStyled = styled.section`
  margin-top: 5rem;

  .wrapper {
    ${standardWrapper};
  }

  .title {
    width: 100%;
    text-align: center;

    h2 {
      ${H1White};
      display: inline-block;
      padding: 0 2rem;
      background-color: ${colors.colorPrimary};
    }
  }

  .content {
    width: 100%;
    max-width: 80rem;
    margin: 4rem auto;
    text-align: center;

    p {
      ${B2LightBlue};
    }
  }
`

export default BoldTitle
