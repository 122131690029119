import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { H2Blue, standardWrapper } from "../../../styles/helpers"

import Fire from "../../Fire"
import BackgroundOne from "../../Icons/BackgroundOne"

const ContentImageIcon = ({ data }) => {
  const imageDisplay = getImage(
    data.ciiImage.localFile.childImageSharp.gatsbyImageData
  )
  const imageAlt = data.ciiImage.altText

  const iconDisplay = getImage(
    data.ciiIcon.localFile.childImageSharp.gatsbyImageData
  )
  const iconAlt = data.ciiIcon.altText

  return (
    <SectionStyled>
      <div className="wrapper">
        <div className="icon">
          <div className="icon-wrap">
            <GatsbyImage
              image={iconDisplay}
              alt={iconAlt}
              layout="fullWidth"
              formats={["auto", "webp", "avif"]}
            />
          </div>
        </div>
      </div>
      <div className="content">
        <div className="content-image">
          <GatsbyImage
            image={imageDisplay}
            alt={imageAlt}
            layout="fullWidth"
            formats={["auto", "webp", "avif"]}
          />
        </div>
        <div
          className="content-para"
          dangerouslySetInnerHTML={{ __html: data.ciiContent }}
        />
      </div>

      <Fire />
      <div className="bg-one">
        <BackgroundOne />
      </div>
    </SectionStyled>
  )
}
const SectionStyled = styled.section`
  position: relative;

  .wrapper {
    ${standardWrapper};
  }

  .icon {
    width: 100%;

    .icon-wrap {
      max-width: 35rem;
      margin: 5rem auto;
    }
  }

  .content {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;

    .content-image {
      width: 100%;

      @media (min-width: 768px) {
        width: calc(50%);
      }
    }

    .content-para {
      width: 100%;
      padding: 2rem;

      @media (min-width: 768px) {
        width: calc(50%);
        max-width: 50rem;
        margin-right: auto;
        margin-left: 0;
        padding: 4rem;
      }

      p {
        ${H2Blue};

        &:last-of-type {
          margin: 0;
        }
      }
    }
  }

  .bg-one {
    position: absolute;
    bottom: -25rem;
    right: -30rem;
    max-width: 40rem;
    margin: auto;
    z-index: 5;

    @media (min-width: 768px) {
      bottom: -25rem;
      right: -30rem;
    }

    @media (min-width: 1025px) {
      bottom: -15rem;
      right: -10rem;
    }
  }
`

export default ContentImageIcon
