import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import {
  Btn1Blue,
  B2LightBlue,
  H2Blue,
  medWrapper,
  colors,
} from "../../../styles/helpers"

const BlockImageLinks = ({ data }) => {
  return (
    <SectionStyled>
      <div className="wrapper">
        {data.bilBlocks.map((block, index) => {
          const imageDisplay = getImage(
            block.image.localFile.childImageSharp.gatsbyImageData
          )
          const imageAlt = block.image.altText
          return (
            <BlockDiv key={index}>
              <div className="block-image">
                <GatsbyImage
                  image={imageDisplay}
                  alt={imageAlt}
                  layout="fullWidth"
                  formats={["auto", "webp", "avif"]}
                />
              </div>
              <div className="block-content">
                <div>
                  <h2>{block.title}</h2>
                </div>
                <div dangerouslySetInnerHTML={{ __html: block.content }} />
                <div>
                  <Link to={`/${block.buttonSlug}`}>{block.buttonText}</Link>
                </div>
              </div>
            </BlockDiv>
          )
        })}
      </div>
    </SectionStyled>
  )
}

const SectionStyled = styled.section`
  .wrapper {
    ${medWrapper};
  }
`

const BlockDiv = styled.div`
  width: 100%;
  margin-bottom: 5rem;
  padding-bottom: 2.5rem;
  border-bottom: 2.5rem solid ${colors.colorPrimary};

  @media (min-width: 768px) {
    width: calc(50% - 4rem);
    margin: 2rem;
  }

  .block-image {
    width: 100%;
  }

  .block-content {
    padding: 0 2rem;

    @media (min-width: 768px) {
      padding: 0 6rem;
    }

    h2 {
      ${H2Blue};
    }

    p {
      ${B2LightBlue};
    }

    a {
      ${Btn1Blue};
    }
  }
`

export default BlockImageLinks
